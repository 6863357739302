import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false

//全局css
import 'swiper/swiper.min.css';
import './assets/css/animate.min.css';
import './assets/css/public.css';
import './assets/css/index.less';

import $ from 'jquery'
Vue.prototype.$ = $;

import * as util from './assets/js/util'
util.screenScrollUnable();
util.setFontSize();
Vue.prototype.util = util;



import CensoredWord from './assets/js/CensoredWord';
Vue.prototype.censoredWord = CensoredWord;


import 'script-loader!createjs/builds/1.0.0/createjs.min.js';

// import layer from 'vue2-layer-mobile'
// Vue.use(layer)

import VueKonva from 'vue-konva'
Vue.use(VueKonva)

import layerBox from './components/layer.vue'
Vue.component("layer", layerBox);

import gsap from "gsap";
Vue.prototype.gsap = gsap;

function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg);  //匹配目标参数
  if (r != null) return unescape(r[2]); return null; //返回参数值
}
let channelid = getUrlParam("ADTAG");
window.beacon = new BeaconAction({
  appkey: "0WEB0R9AYN4R2VBA",
  versionCode: '2.0.0', //项目版本
  channelID: channelid,//渠道号,选填
  autoreport: true, // 是否自动上报页面PV,默认关闭,选填
  isDebug: false,
});
Vue.prototype.report = function (code, option = {}) {
  var data = { "user_id": this.$root.openid };
  Object.assign(data, option);
  console.log('onUserAction',code, data);
  window.beacon.onUserAction(code, data);
}







new Vue({
  render: h => h(App),
  data() {
    return {
      name: '',
      openid: '',
      vott_type: getUrlParam('vo_type') || ''
    }
  }
}).$mount('#app')

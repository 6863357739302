<template>
  <div :class="'card op' + id" ref="card">
    <div class="box" ref="box">
      <div class="mask"></div>
      <div :class="'cover flip '" ref="animationEl2">
        <img :src="cover" alt="" />

        <img :src="title" alt="" class="card-title" />
        <img :src="desc" alt="" class="card-desc" />
      </div>
      <div :class="'cardback flip '" ref="animationEl1">
        <img :src="back" alt="" ref="shareImage" />
        <img src="~img/share.png" alt="" class="ercode" ref="ercode" />

        <p
          class="barp"
          ref="barp"
          :style="
            'left:' +
            left +
            '; top:' +
            top +
            ';background-size: calc(100% - ' +
            pxy +
            'px) 0.26666667vw;'
          "
        >
          科学推荐官<span> {{ $root.name }} </span>最喜欢的前沿科学突破是：
        </p>

        <!-- <template v-if="mbxs">
          <v-stage :config="configKonva" class="stage" :id="'stages' + id">
            <v-layer>
              <v-text :config="configText" ref="text"></v-text>
              <v-line :config="configRect"></v-line>
            </v-layer>
          </v-stage>
        </template> -->
        <img
          :src="createDataURL"
          alt=""
          class="saveImg"
          v-if="createDataURL"
          @touchstart="saveStart"
          @touchend="saveEnd"
          @touchcancel="saveCancel"
        />
      </div>
    </div>
  </div>
</template>
<script>
let dir = window.innerWidth / 750;
let r = (556 / 750) * dir;
r = 1;
console.log(750 * r);
 function getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    }
let config = {
  configKonva: {
    width: 750 * r,
    height: 1250 * r,
  },
  configImg: {
    x: 0,
    y: 0,
    width: 750 * r,
    height: 1250 * r,
  },

  configImga: {
    x: 0,
    y: 0,
    width: 750 * r,
    height: 1250 * r,
  },
};
export default {
  props: [
    "dataURL",
    "cover",
    "back",
    "noSwiping",
    "idxStr",
    "title",
    "desc",
    "id",
    "userPoint",
    "name",
  ],
  watch: {
    dataURL(newV, oldV) {
      console.log("变化");
      // do something
      this.createDataURL || (this.createDataURL = newV);
    },
  },
  components: {},
  data() {
    return {
      flipArr: ["", ""],
      isFlip: false,
      clickFlag: false,
      ...config,
      configRect: {},
      configLine: {},
      configText: {},

      mbxs: 0,
      top: 0,
      left: 0,
      pxy: 0,

      createDataURL: null,
    };
  },
  mounted() {
    this.dir = window.innerWidth / 750;
    let that = this;

    this.pxy = 66 * dir;

    let userPoint = this.userPoint;

    let d = 556 / 750;

    this.left = ((userPoint.x * d) / 750) * 100 + "vw";
    this.top = ((userPoint.y * d) / 750) * 100 + "vw";
  },
  computed: {
    isCurrent() {
      let _newArr = this.idxStr.split(",");
      return _newArr[0] - 1 != _newArr[1];
    },
  },
  methods: {
    saveStart() {
      this.taptime = setTimeout(() => {
        console.log(this.name);
        this.report("click_save_img", { slide_name: this.name });
      }, 500);
    },
    saveEnd() {
      clearTimeout(this.taptime);
    },
    saveCancel() {
      clearTimeout(this.taptime);
    },
    animationEnd(e) {
      this.clickFlag = false;
      // this.$el.removeEventListener(
      //   "webkitAnimationEnd",
      //   this.animationEnd.bind(this)
      // );

      !this.createDataURL && !this.isFlip && this.$emit("canvasinit");
    },
    handleFlipCard(e) {
      if (this.clickFlag || this.isCurrent) return;
      this.clickFlag = true;
      let that = this;
      this.isFlip = this.flipArr[0].indexOf("out") != -1;
      this.flipArr = this.isFlip ? ["dd", "out"] : ["out", "dd"];
      let scale = 1.05;
      let rio = 750 / window.innerWidth;
      let options = {
        scale: scale,
        y: -90 * this.dir,
      };
      let h = window.innerHeight;
      var ua = navigator.userAgent.toLowerCase();
      function isIOS() {
        return /iphone|ipad|ipod|itouch/.test(ua);
      }
      function isWechat() {
        return /micromessenger/.test(ua);
      }
      let isJump = getUrlParam("isJump");

      if (isIOS() && isJump && isWechat()) {
        // h -= 40;
      }

      if (h * rio <= 1334) {
        options.scale = 1;
      }

      if (h * rio <= 1280) {
        options.y = -130 * this.dir;
      }
      let options1 = {
        rotateY: 180,
        onComplete() {
          // that.animationEnd();
        },
      };
      let options2 = {
        rotateY: 0,
        onComplete() {
          that.animationEnd();
        },
      };

      // this.mbxs = 1;
      // this.setFont();
      this.$refs.animationEl1.style.display = "block";

      this.$nextTick(() => {
        // if (that.userPoint.center) {
        // 556

        setTimeout(() => {
          let w = this.$(this.$refs.card)[0].clientWidth;
          let pw = this.$(this.$refs.barp)[0].clientWidth;
          // console.log('left:',this.$(this.$refs.barp).offset);
          let d = w / 750;
          // let pw = this.$(this.$refs.barp).css('left');
          // console.log(pw+that.userPoint.x *d,w);
          //  console.log('x',that.userPoint.x *d );
          if (pw + that.userPoint.x * d >= w) {
            this.left = (w - pw) / 2 + "px";
          }
        }, 50);
        // }
      });

      options.scale = !this.isFlip ? options.scale : 1;
      options.y = !this.isFlip ? options.y : 0;
      // options.rotateY = !this.isFlip ? options.rotateY : 0;
      options1.rotateY = !this.isFlip ? options1.rotateY : 0;
      options2.rotateY = !this.isFlip ? options2.rotateY : 180;
      this.gsap.to(this.$refs.box, 0.4, options);
      this.gsap.to(this.$refs.animationEl2, 0.4, options1);
      this.gsap.to(this.$refs.animationEl1, 0.4, options2);
      this.$emit("handeSwitchPage", { isFlip: this.isFlip });
      this.flipArr = this.isFlip ? ["in", "dd out"] : ["dd out", "in"];
      return;
      this.util.setTimeout(() => {
        this.flipArr = this.isFlip ? ["in", "dd out"] : ["dd out", "in"];
        this.$el = this.isFlip
          ? this.$refs.animationEl2
          : this.$refs.animationEl1;
        this.$el.addEventListener(
          "webkitAnimationEnd",
          this.animationEnd.bind(this)
        );
      }, 30);
    },
  },
};
</script>

<style lang="less">
@vw: 7.5vw;

.saveImg {
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: auto;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
}

.stage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  // opacity: 0;
}
.konvajs-content {
  width: 100% !important;
  height: auto !important;
  canvas {
    width: 100% !important;
    height: auto !important;
  }
}
.card {
  height: (921 / @vw);
  width: (556 / @vw);
  position: relative;
  top: 0;
  left: 50%;
  margin-left: -(556/2 / @vw);

  .box {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .card-title,
  .card-desc {
    position: absolute;
    left: 0;
    // display: none;
  }
  &.op1 {
    .card-title {
      top: (106 * 1.125 / @vw);
    }
    .card-desc {
      top: (219 * 1.125 / @vw);
    }
  }
  &.op2 {
    .card-title {
      top: (108 * 1.125 / @vw);
    }
    .card-desc {
      top: (231 * 1.125 / @vw);
    }
  }
  &.op3 {
    .card-title {
      top: (652 * 1.125 / @vw);
    }
    .card-desc {
      top: (731 * 1.125 / @vw);
    }
  }

  &.op4 {
    .card-title {
      top: (101 * 1.125 / @vw);
    }
    .card-desc {
      top: (234 * 1.125 / @vw);
    }
  }

  &.op5 {
    .card-title {
      top: (546 * 1.125 / @vw);
    }
    .card-desc {
      top: (680 * 1.125 / @vw);
    }
  }

  &.op6 {
    .card-title {
      top: (564 * 1.125 / @vw);
    }
    .card-desc {
      top: (696 * 1.125 / @vw);
    }
  }

  &.op7 {
    .card-title {
      top: (107 * 1.125 / @vw);
    }
    .card-desc {
      top: (220 * 1.125 / @vw);
    }
  }

  &.op8 {
    .card-title {
      top: (75 * 1.125 / @vw);
    }
    .card-desc {
      top: (196 * 1.125 / @vw);
    }
  }
  &.op9 {
    .card-title {
      top: (101 * 1.125 / @vw);
    }
    .card-desc {
      top: (232 * 1.125 / @vw);
    }
  }

  &.op10 {
    .card-title {
      top: (118 * 1.125 / @vw);
    }
    .card-desc {
      top: (251 * 1.125 / @vw);
    }
  }

  &.op11 {
    .card-title {
      top: (99 * 1.125 / @vw);
    }
    .card-desc {
      top: (214 * 1.125 / @vw);
    }
  }

  &.op12 {
    .card-title {
      top: (103 * 1.125 / @vw);
    }
    .card-desc {
      top: (220 * 1.125 / @vw);
    }
  }

  &.op13 {
    .card-title {
      top: (108 * 1.125 / @vw);
    }
    .card-desc {
      top: (226 * 1.125 / @vw);
    }
  }

  &.op14 {
    .card-title {
      top: (607 * 1.125 / @vw);
    }
    .card-desc {
      top: (730 * 1.125 / @vw);
    }
  }

  &.op15 {
    .card-title {
      top: (565 * 1.125 / @vw);
    }
    .card-desc {
      top: (691 * 1.125 / @vw);
    }
  }

  &.op16 {
    .card-title {
      top: (102 * 1.125 / @vw);
    }
    .card-desc {
      top: (231 * 1.125 / @vw);
    }
  }

  &.op17 {
    .card-title {
      top: (508 * 1.125 / @vw);
    }
    .card-desc {
      top: (633 * 1.125 / @vw);
    }
  }

  &.op18 {
    .card-title {
      top: (552 * 1.125 / @vw);
    }
    .card-desc {
      top: (669 * 1.125 / @vw);
    }
  }

  &.op19 {
    .card-title {
      top: (613 * 1.125 / @vw);
    }
    .card-desc {
      top: (716 * 1.125 / @vw);
    }
  }

  &.op20 {
    .card-title {
      top: (561 * 1.125 / @vw);
    }
    .card-desc {
      top: (692 * 1.125 / @vw);
    }
  }

  //  -webkit-perspective:(2000/@vw);
}
.swiper-slide {
  -webkit-transition: -webkit-transform 400ms;
  -webkit-transform: scale(0.67) !important;
  transform-origin: center 44.62%;
  -webkit-transition-timing-function: ease-in-out;

  .card-title,
  .card-desc {
    // display: block;
    // -webkit-animation: 0.5s both dh_contxt;
    transform: translateY((50 / @vw));
    opacity: 0;
    transition: all 0.5s;
  }
}
.swiper-slide-active {
  -webkit-transform: scale(1) !important;
}
.swiper-slide-active {
  .card-title,
  .card-desc {
    transform: translateY(0);
    opacity: 1;
    // display: block;
    // -webkit-animation: 0.5s both dh_contxt;
  }
  .card-desc {
    -webkit-transition-delay: 0.2s;
  }
  &.swiper-no-swiping {
    .card-title,
    .card-desc {
      transform: translateY((50 / @vw));
      opacity: 0;
    }
  }
}
.swiper-slide-prev,
.swiper-slide-next {
  .card-title,
  .card-desc {
    // display: block;
    // -webkit-animation: 0.5s both dh_contxt_dpnone;
    transform: translateY((50 / @vw));
    opacity: 0;
  }
  &.swiper-no-swiping {
    .card-title,
    .card-desc {
      // display: none;
      transform: translateY((50 / @vw));
      opacity: 0;
    }
  }
}
.css-animate {
  .swiper-no-swiping {
    .card-title,
    .card-desc {
      transform: translateY(0);

      // display: block;
      opacity: 1;
    }
  }
}
@-webkit-keyframes dh_contxt_dpnone {
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes dh_contxt {
  0% {
    transform: translateY((50 / @vw));
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
// .cardback{
//   position: absolute;
//   width: 100%;
//   img {
//     width: 100%;
//   }
// }
// .cardback {
//   img {
//     pointer-events: auto;
//   }
// }

.cover,
.cardback {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;

  img {
    width: 100%;
  }
}
.dd {
  display: none;
}
.cardback .ercode {
  position: absolute;
  left: (594 * 0.741 / @vw);
  top: (1107 * 0.741 / @vw);
  width: (107 * 0.741 / @vw);
  height: (107 * 0.741 / @vw);
}
</style>
<template>
  <div class="page1 page">
    <!-- <img src="~img/t-logo.png" alt="" class="t-logo">
    <img src="~img/b-logo.png" alt="" class="b-logo">
    <img src="~img/left-bar.png" alt="" class="l-bar"> -->

    <video
      :style="'width:' + width + 'px;height:' + height + 'px'"
      preload="auto"
      src="https://youth-1258010770.file.myqcloud.com/act/home.mp4"
      playsinline="true"
      :muted="!isplay"
      id="video"
    ></video>

      <!-- poster="../assets/other/home.jpg" -->

    <!-- <canvas id="gka" width="720" height="1280"></canvas> -->
  </div>
</template>
<script>

export default {
  props: ["imgArr", "isplay"],
  components: {},
  data() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },

  mounted: function () {
    this.$video = document.getElementById("video");

    return;

    var canvas = document.getElementById("gka"),
      ctx = canvas.getContext("2d"),
      i = 0,
      o = {},
      key = Object.keys(data.animations)[0],
      list = data.animations[key],
      len = list.length;

    var cacheCanvas = document.createElement("canvas"),
      ctxCache = cacheCanvas.getContext("2d");

    cacheCanvas.width = canvas.width;
    cacheCanvas.height = canvas.height;

    const { sourceW, sourceH } = data;
    const imageRatio = sourceW / sourceH;
    const canvasRatio = canvas.clientWidth / canvas.clientHeight;
    let sx = 0,
      sy = 0,
      sHeight = sourceH,
      sWidth = sourceW;

    if (imageRatio < canvasRatio) {
      sWidth = sourceW;
      sHeight = sWidth / canvasRatio;
      sx = 0;
      sy = 0;
    } else {
      sHeight = sourceH;
      sWidth = sourceH * canvasRatio;
      sy = 0;
      sx = (sourceW - sWidth) / 2;
    }

    window.timer = this.util.setInterval(() => {
      o = list[i];
      ctxCache.clearRect(0, 0, canvas.width, canvas.height);
      o = Object.prototype.toString.call(o) == "[object Array]" ? o : [o];
      for (var j = 0, t; j < o.length; j++) {
        console.log(o[j]);
        t = data.frames[o[j]];
        ctxCache.drawImage(
          this.imgArr[o[j]],
          sx,
          sy,
          sWidth,
          sHeight,
          0,
          0,
          canvas.width,
          canvas.height
        );
      }

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      ctx.drawImage(cacheCanvas, 0, 0, canvas.width, canvas.height);
      i = ++i === len ? 0 : i;
      if (i === len - 1) {
        setTimeout(() => {
          this.util.clearInterval(window.timer);
        }, 0);
        this.$emit("nextPageFun");
      }
    }, 4);
  },
  methods: {},
};
</script>

<style lang="less">
@vw: 7.5vw;
.page1 {
  z-index: 1;
  -webkit-animation-delay: 1.5s;
}
video {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  width: auto;
  object-fit: cover;
}
#gka {
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>

<template>
  <div class="page page3">
    <div class="page bg_kg">
      <div class="bg-img"></div>
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <div class="mask page"></div>
    </div>

    <div class="box-center">
      <div :class="'tips ' + (isFlip ? 'fadeOutUp animated' : '')">
        <div :class="'number cur' + index"></div>
        <div class="desc"></div>
        <div class="prog">
          <div
            :style="'width:' + (index / backImgArr.length) * 100 + '%'"
          ></div>
        </div>
      </div>

      <div class="swiper-container" style="overflow: initial">
        <div :class="'swiper-wrapper ' + (isShow ? 'css-animate' : '')">
          <div
            class="swiper-slide swiper-no-swiping"
            v-for="(item, i) in backImgArr"
            :key="i"
          >
            <card
              :dataURL="dataURL[i]"
              :id="item.id"
              :title="item.title"
              :desc="item.desc"
              :cover="item.u1"
              :back="item.u2"
              :noSwiping="noSwiping"
              :idxStr="index + ',' + i"
              :name="item.name"
              @handeSwitchPage="handeSwitchPage"
              :ref="'card' + i"
              :userPoint="item.userPoint"
              @canvasinit="canvasinit"
            ></card>
          </div>
        </div>
      </div>
      <!-- <transition
        name="btnFade"
        enter-active-class="animated fadeInUp"
        leave-active-class="animated fadeOutDown"
      >
      </transition> -->

      <div class="btn sj" @click="handlerToShare" ref="btn">
        <img src="~img/btn.png" alt="" />
        <div class="animation a4"></div>
      </div>

      <div class="heizi">
        <img src="~img/p1.png" alt="" class="save-info sj" ref="p2" />

        <div class="jump-href sj" ref="p3" @click="gohref">
          <div class="animation a3"></div>
        </div>

        <img src="../assets/img/ss.png" alt="" class="srk" ref="p4" />
        <img src="../assets/img/logos.png" alt="" class="logos" ref="p5" />
      </div>

      <v-stage :config="configKonva" class="stage dd" id="stage" ref="stage">
        <v-layer ref="layer">
          <v-image :config="configImg" ref="image"></v-image>
          <v-image :config="configImga" ref="imagea"></v-image>

          <v-text :config="configText1" ref="text1"></v-text>
          <v-text :config="configText2" ref="text2"></v-text>
          <v-text :config="configText3" ref="text3"></v-text>
          <v-line :config="configRect"></v-line>
        </v-layer>
      </v-stage>
    </div>

    <div class="page layer-config">
      <div class="layer-box animated bounceInUp">
        <img src="~img/lay-1.png" alt="" class="layer-bg" />
        <div class="href-btn btns1" @click="goto">
          <img src="~img/laybtn1.png" />
          <div class="animation a1"></div>
        </div>
        <div class="href-btn btns2" @click="noto">
          <img src="~img/laybtn2.png" />
          <div class="animation a2"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import card from "./card";
   function getUrlParam(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) return unescape(r[2]);
      return null; //返回参数值
    }
function shuffle(arr) {
  for (var i = arr.length - 1; i >= 0; i--) {
    var randomIndex = Math.floor(Math.random() * (i + 1));
    var itemAtIndex = arr[randomIndex];
    arr[randomIndex] = arr[i];
    arr[i] = itemAtIndex;
  }
  return arr;
}
let config = {
  configKonva: {
    width: 750,
    height: 1250,
  },
  configImg: {
    x: 0,
    y: 0,
    width: 750,
    height: 1250,
    image: null,
  },

  configImga: {
    x: 594,
    y: 1107,
    width: 107,
    height: 107,
    image: null,
  },
};

let backImgArr = [
  {
    u1: require("img/f1.png"),
    u2: require("img/s1.jpg"),
    title: require("img/a1.png"),
    desc: require("img/a2.png"),
    id: 1,
    userPoint: {
      x: 38,
      y: 892,
    },
    name: "蛋白质",
  },
  {
    u1: require("img/f2.png"),
    u2: require("img/s2.jpg"),

    title: require("img/a3.png"),
    desc: require("img/a4.png"),
    id: 2,

    userPoint: {
      x: 135,
      y: 154,
      center: 1,
    },
    name: "水世界",
  },
  {
    u1: require("img/f3.png"),
    u2: require("img/s3.jpg"),

    title: require("img/a5.png"),
    desc: require("img/a6.png"),
    id: 3,
    userPoint: {
      x: 37,
      y: 145,
    },
    name: "金属之水",
  },
  {
    u1: require("img/f4.png"),
    u2: require("img/s4.jpg"),

    title: require("img/a7.png"),
    desc: require("img/a8.png"),
    id: 4,

    userPoint: {
      x: 37,
      y: 178,
    },
    name: "复杂的记忆",
  },
  {
    u1: require("img/f5.png"),
    u2: require("img/s5.jpg"),

    title: require("img/a9.png"),
    desc: require("img/a10.png"),
    id: 5,

    userPoint: {
      x: 30,
      y: 830,
    },
    name: "可控核聚变",
  },
  {
    u1: require("img/f6.png"),
    u2: require("img/s6.jpg"),

    title: require("img/a11.png"),
    desc: require("img/a12.png"),
    id: 6,
    userPoint: {
      x: 35,
      y: 153,
    },
    name: "瘫痪失语患者",
  },
  {
    u1: require("img/f7.png"),
    u2: require("img/s7.jpg"),

    title: require("img/113.png"),
    desc: require("img/a13.png"),
    id: 7,
    userPoint: {
      x: 39,
      y: 134,
    },
    name: "月球土壤",
  },
  {
    u1: require("img/f8.png"),
    u2: require("img/s8.jpg"),

    title: require("img/a14.png"),
    desc: require("img/a15.png"),
    id: 8,
    userPoint: {
      x: 138,
      y: 169,
      center: 1,
    },
    name: "最小飞行器",
  },
  {
    u1: require("img/f9.png"),
    u2: require("img/s9.jpg"),

    title: require("img/a16.png"),
    desc: require("img/a17.png"),
    id: 9,

    userPoint: {
      x: 37,
      y: 137,
    },
    name: "猛犸象",
  },
  {
    u1: require("img/f10.png"),
    u2: require("img/s10.jpg"),

    title: require("img/a18.png"),
    desc: require("img/a19.png"),
    id: 10,

    userPoint: {
      x: 175,
      y: 165,
      center: 1,
    },
    name: "火星生物",
  },
  {
    u1: require("img/f11.png"),
    u2: require("img/s11.jpg"),

    title: require("img/a20.png"),
    desc: require("img/a21.png"),
    id: 11,

    userPoint: {
      x: 39,
      y: 146,
    },
    name: "二氧化碳",
  },
  {
    u1: require("img/f12.png"),
    u2: require("img/s12.jpg"),

    title: require("img/a22.png"),
    desc: require("img/a23.png"),
    id: 12,
    userPoint: {
      x: 95,
      y: 124,
      center: 1,
    },
    name: "空间站",
  },
  {
    u1: require("img/f13.png"),
    u2: require("img/s13.jpg"),

    title: require("img/a24.png"),
    desc: require("img/a25.png"),
    id: 13,
    userPoint: {
      x: 32,
      y: 192,
    },
    name: "切尔诺贝利辐射",
  },
  {
    u1: require("img/f14.png"),
    u2: require("img/s14.jpg"),

    title: require("img/a26.png"),
    desc: require("img/a27.png"),
    id: 14,
    userPoint: {
      x: 45,
      y: 105,
    },
    name: "超距作用",
  },
  {
    u1: require("img/f15.png"),
    u2: require("img/s15.jpg"),

    title: require("img/a28.png"),
    desc: require("img/a29.png"),
    id: 15,
    userPoint: {
      x: 39,
      y: 644,
    },
    name: "软体机械鱼",
  },
  {
    u1: require("img/f16.png"),
    u2: require("img/s16.jpg"),

    title: require("img/a30.png"),
    desc: require("img/a31.png"),
    id: 16,
    userPoint: {
      x: 131,
      y: 134,
      center: 1,
    },
    name: "全碳玻璃",
  },
  {
    u1: require("img/f17.png"),
    u2: require("img/s17.jpg"),

    title: require("img/a32.png"),
    desc: require("img/a33.png"),
    id: 17,
    userPoint: {
      x: 32,
      y: 885,
    },
    name: "未知粒子",
  },
  {
    u1: require("img/f18.png"),
    u2: require("img/s18.jpg"),

    title: require("img/a34.png"),
    desc: require("img/a35.png"),
    id: 18,
    userPoint: {
      x: 37,
      y: 102,
    },
    name: "火星内部",
  },
  {
    u1: require("img/f19.png"),
    u2: require("img/s19.jpg"),

    title: require("img/a36.png"),
    desc: require("img/a37.png"),
    id: 19,

    userPoint: {
      x: 44,
      y: 877,
    },
    name: "肥胖基因",
  },
  {
    u1: require("img/f20.png"),
    u2: require("img/s20.jpg"),

    title: require("img/a38.png"),
    desc: require("img/a39.png"),
    id: 20,

    userPoint: {
      x: 36,
      y: 920,
    },
    name: "龙人",
  },
];

export default {
  components: {
    card,
  },
  data() {
    return {
      backImgArr: [],

      index: 0,
      noSwiping: 1,

      isFlip: false,
      isShow: false,

      ...config,
      configRect: {},
      configLine: {},
      configText1: {},
      configText2: {},
      configText3: {},
      dataURL: [],
    };
  },
  created(){
    //  setTimeout(() => {
    //     this.$emit('cssComp');
    // }, 500);
  },
  mounted() {
    // setTimeout(() => {
    //     this.$emit('cssComp');
    // }, 500);

    let _self = this;
    this.moveFlag = true;
    let k = shuffle(backImgArr);
    // let nr = JSON.parse(JSON.stringify(k));
    // nr.length = 10

    this.backImgArr = k;
    this.index = 20;
    //  this.backImgArr = this.backImgArr.concat(arrs[4]);

    // this.$('.animation').on('webkitAnimationEnd',function(){
    //   let $fade = _self.$(this).parents('.fade');
    //   $fade.fadeOut(200);
    //   _self.$(this).css('-webkit-animation-delay','0s');
    //   _self.util.setTimeout(()=>{
    //     $fade.css({'opacity':'1','display':'block'});
    //   },120)
    // })

    this.init();
  },
  methods: {
    setFont(idx, resetX, flag) {
      let { x, y, center } = backImgArr[idx].userPoint;
      resetX && (x = resetX);
      let x1 = x;
      let y1 = y - 2;

      this.configText1 = {
        x: x1 + 12,
        y: y1 + 13,
        // padding: 8,
        text: `科学推荐官`,
        fontSize: 24,
        fontFamily: "HYYAKUHEI45w",
        fill: "#ffffff",
      };

      this.$nextTick(() => {
        if(this.$root.name.length > 7){
          this.$root.name = this.$root.name.substring(0,7) + '...';
        }
        let txtWidth1 = this.$refs.text1.getNode().width();
        this.configText2 = {
          x: this.configText1.x + txtWidth1,
          y: this.configText1.y,
          // padding: 8,
          text: ` ${this.$root.name} `,
          fontSize: 24,
          fontFamily: "HYYAKUHEI75w",
          fill: "#ffffff",
        };
        this.$nextTick(() => {
          let txtWidth2 = this.$refs.text2.getNode().width();
          this.configText3 = {
            x: this.configText2.x + txtWidth2,
            y: this.configText1.y,
            // padding: 8,
            text: ` 最喜欢的前沿科学突破是：`,
            fontSize: 24,
            fontFamily: "HYYAKUHEI45w",
            fill: "#ffffff",
          };
          this.$nextTick(() => {
            let w =
              txtWidth1 + txtWidth2 + this.$refs.text3.getNode().width() + 12;
            let x2 = x + w;
            let y2 = y1;
            let x3 = x2;
            let y3 = y + 43 + 2;
            let x4 = x + w * 0.9;
            let y4 = y3;
            let x5 = x4 - 8;
            let y5 = y3 + 8;
            let x6 = x5 - 8;
            let y6 = y3;
            let x7 = x1;
            let y7 = y3;
            let x8 = x1;
            let y8 = y1;
            this.configRect = {
              points: [
                x1,
                y1,
                x2,
                y2,
                x3,
                y3,
                x4,
                y4,
                x5,
                y5,
                x6,
                y6,
                x7,
                y7,
                x8,
                y8,
              ],
              stroke: "#ffffff",
              strokeWidth: 1,
              closed: true,
              opacity: 1,
            };
            console.log(w + x1, 750);
            if ((w + x1 >= 750 || center) && !flag) {
              let resetX = (750 - w) / 2;
              console.log("resetX", resetX);
              this.setFont(idx, resetX, 1);
            }
            // this.canvasinit();
          });
        });
      });
      // this.$refs.layer.getNode().add(this.txt1);
    },

    canvasinit() {
      let that = this;

      // if (that.dataURL) return;

      let idx = this.index - 1;

      if (this.$refs["card" + idx][0].$refs.shareImage.complete) {
        loadimg(this.$refs["card" + idx][0].$refs.shareImage);
      } else {
        this.$refs["card" + idx][0].$refs.shareImage.onload = function () {
          loadimg(this);
        };
      }
      function loadimg(img) {
        // const image = that.$refs.image.getNode();
        // image.image(img);
        console.log("image1", img);

        that.configImg.image = img;

        if (that.$refs["card" + idx][0].$refs.ercode.complete) {
          ercodeLoad(that.$refs["card" + idx][0].$refs.ercode);
        } else {
          that.$refs["card" + idx][0].$refs.ercode.onload = function () {
            ercodeLoad(that);
          };
        }
        function ercodeLoad(img) {
          console.log("image2", img);
          // const imagea = that.$refs.imagea.getNode();
          // imagea.image(img);

          that.configImga.image = img;

          that.$nextTick(() => {
            const stage = that.$refs.stage.getNode();
            // let newDataArr = that.dataURL;
            that.util.setTimeout(() => {
              let dataURL = stage.toDataURL({
                pixelRatio: 1,
              });
              that.$set(that.dataURL, idx, dataURL);
            }, 60);
          });
        }
      }
    },
    prveShow() {
      console.log(666);
      this.$nextTick(() => {
        let _self = this;
        _self.moveFlag = false;
        // timeout = timeout < 5 ? 5 : parseInt(timeout - timeout1 * s);
        // swiper.slidePrev();
        // _self.index = _self.swiper.activeIndex - 1;

        _self
          .$(".swiper-wrapper")
          .css("transition-timing-function", "ease-in-out");
        _self.swiper.slideTo(0, 3000, true);

        let time = _self.util.setInterval(() => {
          if (_self.index == 1) {
            setTimeout(() => {
              _self.util.clearInterval(time);
            });
            return;
          }
          _self.index--;
        }, 8);

        // _self.index = _self.index < 0 ? 1 : _self.index;
        // swiper.activeIndex && _self.util.setTimeout(prveShow, 10);

        // if(self.index > 0){
        // _self.$(".swiper-slide-active").removeClass("swiper-slide-active");
        // }

        // this.$('.swiper-slide-visible').removeClass('swiper-slide-visible');
        // this.$('.swiper-slide-prev').removeClass('swiper-slide-prev');
        // this.$('.swiper-slide-next').removeClass('swiper-slide-next');
      });
    },
    init() {
      let _self = this;

      this.$nextTick(() => {
        this.swiper = new Swiper(".swiper-container", {
          slidesPerView: 3,
          centeredSlides: true,
          autoHeight: true,
          initialSlide: 20,
          observer: true,
          observeParents: true,
          on: {
            init: function (swiper) {
              _self.prveShow();
            },
            slideChangeTransitionEnd(e) {
              if (!_self.moveFlag) {
                _self.index = 1;
                _self.noSwiping = 0;
                _self.moveFlag = true;

                _self.$nextTick(() => {
                  _self.gsap.to(_self.$refs.btn, 0.4, {
                    autoAlpha: 1,
                  });
                });
                _self.isShow = 1;
                _self.addOrReduce();
              }
              if (_self.noSwiping) return;

              // this.$('.swiper-slide').addClass('cur');

              _self.index = this.activeIndex + 1;

              _self.report("slide_mv");
            },
          },
        });
      });
    },
    handlerToShare() {
      let idx = this.index - 1;
      let name = this.backImgArr[idx].name;
      this.report("click_recommend", { slide_name: name });

      this.setFont(idx);

      this.$refs[`card${idx}`][0].handleFlipCard();
    },
    handeSwitchPage(options) {
      this.isFlip = !options.isFlip;
      this.noSwiping = this.isFlip ? 1 : 0;

      this.isFlip && this.$emit("setFilpState");

      this.$nextTick(() => {
        this.gsap.to(this.$refs.btn, 0.5, {
          autoAlpha: this.isFlip ? 0 : 1,
          y: this.isFlip ? 50 : 0,
        });
        this.gsap.to(this.$refs.p2, 0.5, {
          autoAlpha: this.isFlip ? 1 : 0,
          y: this.isFlip ? 0 : 50,
        });
        this.gsap.to(this.$refs.p3, 0.5, {
          autoAlpha: this.isFlip ? 1 : 0,
          y: this.isFlip ? 0 : 50,
          delay: 0.1,
        });
        this.gsap.to(this.$refs.p4, 0.5, {
          autoAlpha: this.isFlip ? 1 : 0,
          y: this.isFlip ? 0 : 50,
          delay: 0.2,
        });
        this.gsap.to(this.$refs.p5, 0.5, {
          autoAlpha: this.isFlip ? 1 : 0,
          y: this.isFlip ? 0 : 50,
          delay: 0.3,
        });
      });

      // this.$refs.p2,this.$refs.p3

      // this.idx = options.idx
      this.addOrReduce(1);
    },
    addOrReduce(flag) {
      let $noSwiping = document.querySelectorAll(".swiper-slide");
      $noSwiping.forEach((e, key) => {
        if (this.index - 1 != key && this.noSwiping) {
          this.gsap.to(e, 0.4, { autoAlpha: 0 });
        } else if (flag) {
          this.gsap.to(e, 0.4, { autoAlpha: 1 });
        }

        this.noSwiping
          ? e.classList.add("swiper-no-swiping")
          : e.classList.remove("swiper-no-swiping");
      });
    },
    goto() {
      // this.gsap.from(this.$('.btns1'),1,{scale:1},{scale:1})
      let idx = this.index - 1;
      let name = this.backImgArr[idx].name;
      this.report("click_activity_yes", { slide_name: name });
      setTimeout(()=>{
        location.href = "https://tencentyouth.qq.com/census/index-m.html";
      },100)
    },
    noto() {
      // this.gsap.to(".btns2", { duration: 0.2, scale: 1.05 });
      // this.gsap.to(".btns2", { duration: 0.2, delay: 0.2, scale: 1 });

      let idx = this.index - 1;
      let name = this.backImgArr[idx].name;
      this.report("click_activity_no", { slide_name: name });

      this.$(".layer-config").fadeOut(500);
    },
    gohref() {
      let idx = this.index - 1;
      let name = this.backImgArr[idx].name;
      this.report("click_activity", { slide_name: name });

      this.gsap.to(".page3 .jump-href", { duration: 0.4, scale: 1.05 });
      this.gsap.to(".page3 .jump-href", {
        duration: 0.4,
        delay: 0.4,
        scale: 1,
      });
      this.$(".layer-config").fadeIn(500);
      // location.href = "http://tencentyouth.qq.com/census/#/";
    },
  },
};
</script>

<style lang="less">
@vw: 7.5vw;
.page3 {
  .a3 {
    -webkit-transform: scale(0.6);
    left: (0 / @vw);
    top: (-40 / @vw);
  }
  .a4 {
    -webkit-transform: scale(0.9);
    left: (0 / @vw);
    top: (-25 / @vw);
  }
}
.layer-config {
  background: rgba(0, 0, 0, 0.6);
  z-index: 99999999;
  display: none;
  .layer-box {
    position: absolute;
    left: 0;
    top: 0;
    width: (410 / @vw);
    height: (283 / @vw);
    right: 0;
    bottom: 0;
    margin: auto;
    .layer-bg {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .btns1 {
      position: absolute;
      left: (80 / @vw);
      top: (180 / @vw);
      width: (96 / @vw);
      height: (55 / @vw);
      img {
        width: 100%;
        display: block;
        // position: absolute;
        // top: 0;
      }
    }
    .btns2 {
      position: absolute;
      left: (235 / @vw);
      top: (180 / @vw);
      width: (96 / @vw);
      height: (55 / @vw);
      img {
        width: 100%;
        display: block;
        //   position: absolute;
        // top: 0;
      }
    }
    .a1 {
      -webkit-transform: scale(0.5);
      left: (-100 / @vw);
      top: (-55 / @vw);
    }
    .a2 {
      -webkit-transform: scale(0.5);
      left: (-100 / @vw);
      top: (-55 / @vw);
      -webkit-animation-delay: -1s;
    }
  }
}
.heizi {
  position: absolute;
  left: 0;
  top: (1072 / @vw);
  width: 100%;
}

.save-info {
  position: absolute;
  left: 50%;
  top: 0;
  width: (358 / @vw);
  margin-left: -(358/2 / @vw);

  transform: translateY(50px);
}
.jump-href {
  position: absolute;
  left: 50%;
  top: (43 / @vw);
  width: (290 / @vw);
  height: (82 / @vw);
  z-index: 99999;
  margin-left: -(290/2 / @vw);

  background: url("~img/p2.png") no-repeat;
  background-size: 100% auto;
  transform: translateY(50px);
}
.srk {
  position: absolute;
  left: (249 / @vw);
  top: (148 / @vw);
  // bottom: (82 / @vw);
  width: (248 / @vw);
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
}
.logos {
  position: absolute;
  left: 0px;
  top: (219 / @vw);
  width: 100%;
  transform: translateY(50px);
  opacity: 0;
  visibility: hidden;
}
.swiper-container {
  width: (1514 / @vw);
  height: (921 / @vw);
  overflow: hidden;
  position: absolute;
  left: 50%;
  margin-left: -(1514/2 / @vw);
  top: (198 / @vw);
}

.tips {
  width: (446 / @vw);
  height: (83 / @vw);
  position: absolute;
  top: (68 / @vw);
  left: 50%;
  margin-left: -(446/2 / @vw);
  .number {
    width: (87 / @vw);
    height: (26 / @vw);
    position: absolute;
    left: 50%;
    margin-left: -(87/2 / @vw);
    background: url("~img/number.png") no-repeat;
    background-size: (372 / @vw) (363 / @vw);
    &.cur1 {
      background-position: (-117 / @vw) (-327 / @vw);
    }
    &.cur2 {
      background-position: (-10 / @vw) (-327 / @vw);
    }
    &.cur3 {
      background-position: (-224 / @vw) (-281 / @vw);
    }
    &.cur4 {
      background-position: (-117 / @vw) (-281 / @vw);
    }
    &.cur5 {
      background-position: (-10 / @vw) (-281 / @vw);
    }
    &.cur6 {
      background-position: (-224 / @vw) (-235 / @vw);
    }
    &.cur7 {
      background-position: (-117 / @vw) (-235 / @vw);
    }
    &.cur8 {
      background-position: (-10 / @vw) (-235 / @vw);
    }
    &.cur9 {
      background-position: (-224 / @vw) (-189 / @vw);
    }
    &.cur10 {
      background-position: (-117 / @vw) (-189 / @vw);
    }
    &.cur11 {
      background-position: (-10 / @vw) (-189 / @vw);
    }
    &.cur12 {
      background-position: (-224 / @vw) (-143 / @vw);
    }
    &.cur13 {
      background-position: (-117 / @vw) (-143 / @vw);
    }
    &.cur14 {
      background-position: (-10 / @vw) (-143 / @vw);
    }
    &.cur15 {
      background-position: (-224 / @vw) (-97 / @vw);
    }
    &.cur16 {
      background-position: (-117 / @vw) (-97 / @vw);
    }
    &.cur17 {
      background-position: (-10 / @vw) (-97 / @vw);
    }
    &.cur18 {
      background-position: (-224 / @vw) (-51 / @vw);
    }
    &.cur19 {
      background-position: (-117 / @vw) (-51 / @vw);
    }
    &.cur20 {
      background-position: (-10 / @vw) (-51 / @vw);
    }
  }

  .desc {
    width: (370 / @vw);
    height: (23 / @vw);
    position: absolute;
    left: 50%;
    margin-left: -(370/2 / @vw);
    background: url("~img/txt-1.png") no-repeat;
    background-size: (370 / @vw) auto;
    top: (62 / @vw);
  }
  .prog {
    background: url("~img/ban.png");
    background-size: 100% auto;
    width: (445 / @vw);
    height: (9 / @vw);
    position: absolute;
    top: (40 / @vw);
    left: 0;
    div {
      background: url("~img/ban1.png");
      background-size: (445 / @vw) (9 / @vw);
      width: (445 / @vw);
      height: (9 / @vw);
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transition: width 400ms;
    }
  }
}
</style>
<template>
  <div id="app" :style="'height:' + innerHeight + 'px'" :class="isSafari">
    <transition-group name="page" appear tag="div" class="group">
      <loading
        v-if="index == 0"
        key="loading"
        @complete="onLoadComplete"
      ></loading>
      <page3
        ref="page3"
        @setFilpState="setFilpState"
        key="io3"
        v-if="index == 3 || isFlip"
        @cssComp="cssComp"
      ></page3>
    </transition-group>
    <img
      src="~img/return.png"
      alt=""
      class="back"
      @click="backPrvePage"
      v-if="index == 3"
      key="io1"
    />

    <page2 ref="page2" @nextPageFun="nextPageFun" key="io4"></page2>

    <!-- <page3
      v-if="index == 3 || isFlip"
      ref="page3"
      @setFilpState="setFilpState"
      key="io3"
    ></page3> -->

    <page1
      ref="page1"
      @nextPageFun="nextPageFun"
      key="io2"
      v-show="index != -1"
      :isplay="isplay"
    ></page1>

    <div
      class="audio-icon"
      @click="playAudio"
      v-if="!isload"
      :class="'audio-page' + index"
    >
      <img src="~img/audio-icon.png" alt="" v-if="!isplay" />
      <img src="~img/audio-icon-play.png" alt="" v-else />
    </div>

    <audio
      src="https://youth-1258010770.file.myqcloud.com/act/audio.mp3"
      id="audio"
      style="display: none"
      loop
    ></audio>

    <div id="orientLayer" class="mod-orient-layer">
      <div class="mod-orient-layer__content">
        <i class="icon-wx mod-orient-layer__icon-orient"></i>
        <div class="mod-orient-layer__desc">为了更好的体验，请使用竖屏浏览</div>
      </div>
    </div>
  </div>
</template>

<script>
import page3 from "./components/page3";
import page2 from "./components/page2";
import page1 from "./components/page1";
import loading from "./components/loading";
// import WechatJSSDK from "@yushuo/wechat-jssdk";
import txjsdk from "./assets/js/txjssdk";
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}
export default {
  name: "App",

  components: {
    page1,
    page2,
    page3,
    loading,
  },
  data() {
    return {
      index: 0,
      isFlip: false,
      isload: true,
      imgArr: [],
      innerHeight: window.innerHeight,

      isplay: true,
      isSafari: [],
    };
  },
  computed: {},
  created() {
    let that = this;

    this.cssComp(); //页面适配

    let data = {
      shareTitle: "科学还能这么好看？！",
      shareDesc: "来《青少年科学看点榜单》，评选超好看科学！",
      shareImage: "https://tencentysf2021.qq.com/share.jpg?v=1",
    };

    let isJump = getUrlParam("isJump");

    if (isJump) {
      this.isload = false;
      this.index = 3;
      this.cssComp();

      function onBridgeReady() {
        console.log(55555);
        WeixinJSBridge.call("hideToolbar");
      }

      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }

      // data.getUserinfo = function (userinfo) {
      //   if (userinfo) {
      //     that.$root.openid = userinfo.openid;
      //     that.$root.name = userinfo.nickname;
      //   }
      // };
    }
    console.log("created");
    txjsdk.init(data);
  },
  mounted() {
    document.getElementById("video") && document.getElementById("video").play();
    document.getElementById("video") &&
      document.getElementById("video").pause();

    // const noScroll = (e) => {
    //   e.preventDefault();
    // };

    // let $load = document.querySelector(".loading");
    // $load && $load.addEventListener("touchmove", noScroll, {
    //   passive: false,
    // });
    // let $page1 = document.querySelector(".page1");
    // $page1 && $page1.addEventListener("touchmove", noScroll, {
    //   passive: false,
    // });
    // let $page2 = document.querySelector(".page2");
    // $page2 && $page2.addEventListener("touchmove", noScroll, {
    //   passive: false,
    // });

    let that = this;
    this.curDate = new Date().getTime();

    document.addEventListener("visibilitychange", function () {
      if (document.visibilityState === "visible") {
        //  that.cssComp();
      } else {
        that.report("total_duration", {
          time: new Date().getTime() - that.curDate,
        });
      }
    });
  },
  methods: {
    cssComp() {
      this.isSafari = [];
      console.log("cssComp");
      function isSafari() {
        let isSafari = "";
        if (
          /Safari/.test(navigator.userAgent) &&
          !/Chrome/.test(navigator.userAgent)
        ) {
          isSafari = "isSafari";
        }
        return isSafari;
      }
      if (isSafari()) {
        this.isSafari.push("isSafari");
      }
      let w = window.innerWidth;
      let h = window.innerHeight;
      let r = 750 / w;
      var ua = navigator.userAgent.toLowerCase();
      function isIOS() {
        return /iphone|ipad|ipod|itouch/.test(ua);
      }
      function isWechat() {
        return /micromessenger/.test(ua);
      }
      let isJump = getUrlParam("isJump");

      if (isIOS() && isJump && isWechat()) {
        // h -= 140;
        // h -= 40;
      }
      this.innerHeight = h;

      // if (isIOS()) {
      //   // h -= 70;
      // }
      console.log("height6666666", h * r);
      if (h * r <= 1334) {
        this.isSafari.push("max-css1334");
      }
      if (h * r <= 1280) {
        this.isSafari.push("max-css1280");
      }

      if (h * r <= 1230) {
        this.isSafari.push("max-css1230");
      }
      if (h * r <= 1210) {
        this.isSafari.push("max-css1210");
      }
      if (h * r <= 1200) {
        this.isSafari.push("max-css1200");
      }

      if (h * r >= 1380) {
        this.isSafari.push("min-css1380");
      }
      if (h * r >= 1335) {
        this.isSafari.push("min-css1335");
      }
    },
    playAudio() {
      let $audio = document.getElementById("audio");
      let $video = document.getElementById("video");

      this.isplay = !this.isplay;
      if ($audio.paused && $video.paused) {
        $audio.play();
      } else {
        $audio.pause();
      }
    },
    nextPageFun() {
      this.index = this.index + 1;

      this.$nextTick(() => {
        if (this.index == 2) {
          console.log(this.$(".page2"));
          this.$(".page2").fadeIn(500);
        } else if (this.index == 3) {
          this.$(".page1").remove();
          this.$(".page2").fadeOut(500);
          // this.$(".page3").fadeIn(500);
          //  this.$refs.page3.init();
        }
      });

      console.log(this.index);

      // if (this.index == 3) {
      //   this.$refs.page3.init();
      // }
    },
    setFilpState() {
      this.isFlip = true;
    },
    backPrvePage() {
      if (this.index == 3 && this.isFlip) {
        this.isFlip = false;
        this.$refs.page3.handlerToShare();
        return;
      }
      if (this.index == 3) {
        this.$(".page3").fadeOut(500);
        this.$(".page2").fadeIn(500);
      }
      console.log(this.index);
      this.index -= 1;
    },
    onLoadComplete() {
      let that = this;
      console.log(5);
      let $audio = document.getElementById("audio");

      if (!$audio.paused) $audio.pause();

      document.getElementById("video").play();
      // this.playAudio();
      // this.$refs.page1.$el.classList.add("cur");

      // document.getElementById('audio').pause();

      // this.$refs.page1.$video.play();

      // this.isload = false;
      // this.nextPageFun();

      that.isload = false;
      that.nextPageFun();

      let flag = 0;

      that.util.clearInterval(window.timer);
      that.gsap.to(".page1", 1, { autoAlpha: 1 });
      // this.$refs.page1.$video.ontimeupdate = function () {
      //   console.log("currentTime", this.currentTime);
      //   if (!flag && this.currentTime > 0.1) {
      //     that.util.clearInterval(window.timer);
      //     flag = 1;
      //     that.gsap.to(".page1", 1, { autoAlpha: 1 });
      //   }
      // };

      this.$refs.page1.$video.onended = () => {
        if (this.isplay) document.getElementById("audio").play();
        console.log("播放完成");
        this.nextPageFun();
      };

      // this.$root.$video.play();
      // this.imgArr = arr;
    },

    loadEnd() {
      let frameArr = [];
      for (let i = 1; i <= 20; i++) {
        frameArr.push({ src: require("img/f" + i + ".png") });
      }
      for (let i = 1; i <= 20; i++) {
        frameArr.push({ src: require("img/s" + i + ".jpg") });
      }
      let fileList = [
        {
          src: require("img/p3.png"),
        },
        {
          src: require("img/p10.png"),
        },
      ];

      let waitFor = [
        {
          src: require("img/p2-bg.jpg"),
        },
        {
          src: require("img/qie.png"),
        },
        {
          src: require("img/number.png"),
        },
        {
          src: require("img/p2-bg.jpg"),
        },
        {
          src: require("img/p5.png"),
        },
        ...frameArr,
        ...fileList,
      ];
      let preload = new createjs.LoadQueue(false);
      preload.setMaxConnections(1);
      preload.loadManifest(waitFor);
      preload.addEventListener("complete", () => {});
    },
  },
};
</script>

<style>
</style>

<template>
  <div class="loading page" @click="videoPlay" v-if="isFirst">
    <div class="page bg_kg">
      <div class="bg-img"></div>
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <div class="mask page"></div>
    </div>

    <div class="canvas-box">
      <canvas-assembly
        ref="canvas"
        :loadData="loadData"
        :src="src"
        @callback="frameCallback"
      ></canvas-assembly>

      <p ref="p" v-show="!isemitComplete">
        <span
          v-for="(item, key) in numArr"
          :key="key"
          :style="
            key != numArr.length - 1
              ? 'background: url(' +
                imgurl +
                ') no-repeat; background-size: 2.66666667vw auto; background-position:0 -' +
                4.13333333 * item +
                'vw'
              : ''
          "
        ></span>
      </p>

      <div class="startBtn" v-show="isemitComplete">
        <img src="~img/t.png" alt="" />
        <!-- <img src="~img/jt.png" alt="" class="jt jt1">
        <img src="~img/jt.png" alt="" class="jt jt2">
        <img src="~img/jt.png" alt="" class="jt jt3">
        <img src="~img/jt.png" alt="" class="jt jt4"> -->
        <div class="yuan">
          <img src="~img/yuan.png" alt="" class="yuandong" />
          <img src="~img/yuan.png" alt="" class="yuandong" />
          <img src="~img/yuan.png" alt="" class="yuandong" />
          <img src="~img/shou.png" alt="" class="shou" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { data } from "img/frame/frame";
import canvasAssembly from "./canvas-assembly.vue";
let loadImgList = [
  { id: "1", src: require("img/frame/2.png") },
  { src: require("img/loading.jpg") },
  { src: require("img/num.png") },
];
let frameArr = [];
for (let i = 1; i <= 20; i++) {
  frameArr.push({ src: require("img/f" + i + ".png") });
}
for (let i = 1; i <= 20; i++) {
  frameArr.push({ src: require("img/s" + i + ".jpg") });
}
let fileList = [
  {
    src: require("img/p3.png"),
  },
  {
    src: require("img/p10.png"),
  },
];

let waitFor = [
    {
    
    src:require("img/p2-bg.jpg"),
  },
  {
    
    src:require("img/qie.png"),
  },
  {
    src: require("img/number.png"),
  }, {
    src: require("img/p2-bg.jpg"),
  },
   {
    src: require("img/p5.png"),
  },
   ...frameArr,
    ...fileList,
];


export default {
  components: {
    canvasAssembly,
  },
  data() {
    return {
      currentProgress: 0, //当前进度
      imagesProgress: 0,
      currentFrame: 0,
      speed: 0,
      status: null,

      numArr: [0, "%"],
      imgurl: require("img/num.png"),

      isFirst: 0,
      isemitComplete: 0,
      loadData: data,
      src: "",
      isFrame: 0,
      flag: false,
    };
  },

  mounted: function () {
    console.log(window.beacon);

    let that = this;
    let preload = new createjs.LoadQueue(true);
    preload.setMaxConnections(1);
    preload.loadManifest(loadImgList);
    preload.addEventListener("error", that.onError);
    preload.addEventListener("complete", () => {
      that.src = preload.getResult("1");
      console.log("src", that.src);
      that.isFirst = 1;
      loadNext();
    });

    function loadNext() {
      // let preload1 = new createjs.LoadQueue(false);
      // // preload1.addEventListener("error", that.onError);
      // preload1.addEventListener("progress", that.onLoadProgress.bind(that));
      // preload1.addEventListener("complete", () => {
      //   // let sound = preload1.getResult("sound");
      //   // console.log("sound-----------", sound);
      //   // that.$root.$audio = sound;

      //   // document.addEventListener(
      //   //   "WeixinJSBridgeReady",
      //   //   function () {
      //   //     that.$root.$audio.play();
      //   //     that.$root.$audio.pause();
      //   //      if (that.$root.weixinload) {
      //   //       that.emitComplete();
      //   //     }
      //   //     that.$root.weixinload = true;
      //   //   },
      //   //   false
      //   // );
      //   that.isComplete = 1;
      // });
      // preload1.loadManifest(fileList);
      that.imagesProgress = 100;
      loadEnd();

      that.play();
    }
    function loadEnd() {
      let preload = new createjs.LoadQueue(false);
      preload.setMaxConnections(1);
      preload.loadManifest(waitFor);
      preload.addEventListener("error", that.onError);
      preload.addEventListener("complete", () => {});
    }

    // this.startPreload(
    //   loadImgList,
    //   (pre) => {
    //     preload = pre;
    //     console.log("preload", preload);

    //     this.isFirst = 1;
    //     this.src = preload.getResult("1");
    //     console.log("load-1", this.src);
    //     // this.currentProgress=0;
    //     // this.imagesProgress=0;
    //     // this.currentFrame=0;
    //     // this.speed=0;
    //     // this.status= null,
    //     this.startPreload(
    //       fileList,
    //       (pre) => {
    //         preload1 = pre;
    //         console.log("preload1", preload1);
    //         // this.emitComplete();

    //         this.startPreload(waitFor, () => {}, false);
    //       },
    //       true
    //     );
    //     this.play();
    //   },
    //   false
    // );
    // this.play();
  },
  methods: {
    frameCallback() {
      this.isFrame = 1;
    },
    play() {
      // console.log("currentProgress", this.currentProgress);
      // console.log("imagesProgress", this.imagesProgress);
      // console.log("isFrame", this.isFrame);
      // console.log("isComplete", this.isComplete);
      if (
        this.currentProgress === 100 &&
        this.imagesProgress === 100 
      ) {
        this.util.clearTimeout(this.status);
        this.emitComplete();
      } else {
        if (this.currentProgress >= this.imagesProgress) {
          ("Do Nothing");
        } else if (this.currentFrame < this.speed) {
          this.currentFrame++;
        } else {
          this.currentFrame = 0;
          this.currentProgress++;
          this.emitProgress();
        }
        // this.status = requestAnimationFrame(this.play.bind(this));

        this.status = this.util.setTimeout(this.play.bind(this), 2);
      }
    },
    emitProgress() {
      let progress = this.currentProgress;
      let y = this.bezierEaseOut(progress, 0, 100, 100) | 0;
      // console.log("当前进度：" + y);
      y = y.toString();
      let data = [];
      for (var i = 0, len = y.length; i < len; i += 1) {
        data.push(+y.charAt(i));
      }

      data.push("%");

      this.numArr = data;

      // this.$refs.p.innerHTML = y+'%'
      // $('.loading p').text(y + '%');
      // console.log(this.currentProgress);
    },
    bezierEaseOut(t, b, c, d) {
      let t2 = t / d - 1;
      return c * Math.sqrt(1 - (t / d - 1) * t2) + b;
    },
    onLoadProgress: function (event) {
      this.imagesProgress = Math.ceil(event.loaded * 100);
      console.log("加载进度：" + this.imagesProgress);
    },
    onError(e) {
      console.log("图片加载错误", e);
    },
    emitComplete() {
      console.log("加载完成1");

      // let arr = [];

      // for (let i = 1; i <= 175; i++) {
      //   // console.log(preload1);
      //   arr.push(preload1.getResult("home" + i));
      //   // console.log(preload1.getResult("home" + i));
      // }
      // console.log(preload);
      // preload.reset();
      this.isemitComplete = 1;
    },
    onLoadComplete(event) {
      console.log("加载完成2");
      this.isComplete = true;
      // this.$emit("completes");
    },
    videoPlay() {
      if (!this.isemitComplete) return;

      this.report('click_story');

      this.$emit("complete");
    },
  },
};
</script>
<style lang="less">
@vw: 7.5vw;

.startBtn {
  position: absolute;
  left: 50%;
  width: (284 / @vw);
  height: (30 / @vw);
  margin-left: -(284/2 / @vw);
  bottom: -(60 / @vw);
  pointer-events: auto;
  -webkit-animation: 1s both opacity1;
  img {
    width: 100%;
    position: absolute;
    top: 0;

    &.jt {
      width: (12 / @vw);
      position: absolute;
      top: 0;
      right: 0;
    }
    &.jt1 {
      right: 0;
      -webkit-animation: jt4 1s both linear infinite;
    }
    &.jt2 {
      right: (12 / @vw);
      -webkit-animation: jt3 1s both linear infinite;
    }
    &.jt3 {
      right: (24 / @vw);
      -webkit-animation: jt2 1s both linear infinite;
    }
    &.jt4 {
      right: (36 / @vw);
      -webkit-animation: jt1 1s both linear infinite;
    }
  }
}

@-webkit-keyframes jt1 {
  0% {
    opacity: 1;
  }
  12.5% {
    opacity: 0;
    transform: translateX((5 / @vw));
  }
  25% {
    opacity: 1;
  }
}
@-webkit-keyframes jt2 {
  25% {
    opacity: 1;
  }
  37.5% {
    opacity: 0;
    transform: translateX((5 / @vw));
  }
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes jt3 {
  50% {
    opacity: 1;
  }
  62.5% {
    opacity: 0;
    transform: translateX((5 / @vw));
  }
  75% {
    opacity: 1;
  }
}
@-webkit-keyframes jt4 {
  75% {
    opacity: 1;
  }
  87.5% {
    opacity: 0;
    transform: translateX((5 / @vw));
  }
  100% {
    opacity: 1;
  }
}

.canvas-box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: (162 / @vw);
  height: (218 / @vw);
  margin-top: (-107.5 / @vw);
  margin-left: (-162/2 / @vw);
  p {
    // font-family: "HYYAKUHEI";
    font-size: (34 / @vw);
    color: #fff;
    position: absolute;
    //  top: 0;
    bottom: -(60 / @vw);
    left: 0;
    width: 100%;
    text-align: center;

    display: flex;
    align-items: center;
    justify-content: center;
    span {
      width: (20 / @vw);
      height: (31 / @vw);
      &:last-child {
        background: url("~img/sz/10.png") no-repeat;
        background-size: (20 / @vw) auto;
      }
    }
  }
  canvas {
    width: 100%;
    // height: 100%;
    position: absolute;
  }
}

.yuan {
  position: absolute;
  left: (251 / @vw);
  width: (85 / @vw);
  height: (85 / @vw);
  top: (8 / @vw);
  .yuandong {
    width: (16 / @vw);
    position: absolute;
    -webkit-animation: 2s both linear infinite yuan;

    &:nth-child(2) {
      -webkit-animation-delay: -0.65s;
    }

    &:nth-child(3) {
      -webkit-animation-delay: -1.3s;
    }
  }
  .shou {
    position: absolute;
    top: (-5 / @vw);
    left: -(5 / @vw);
    width: (33 / @vw);
    -webkit-animation: 2s both infinite shou;
  }
}
@-webkit-keyframes yuan {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2.5);
    opacity: 0;
  }
}
@-webkit-keyframes shou {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.25);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
</style>
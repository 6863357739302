

//解锁屏幕滑动
export const screenScrollEnable = () => {
  document.removeEventListener('touchmove', noScroll, {
    passive: false
  });
}
//锁定屏幕滑动
export const screenScrollUnable = () => {
  document.addEventListener('touchmove', noScroll, {
    passive: false
  });
}
const noScroll = (e) => {
  e.preventDefault();
}
export const setFontSize = () => {
  (function () {
    if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
      handleFontSize();
    } else {
      if (document.addEventListener) {
        document.addEventListener("WeixinJSBridgeReady", handleFontSize, false);
      } else if (document.attachEvent) {
        document.attachEvent("WeixinJSBridgeReady", handleFontSize);
        document.attachEvent("onWeixinJSBridgeReady", handleFontSize);
      }
    }
    function handleFontSize() {
      // 设置网页字体为默认大小
      WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
      // 重写设置网页字体大小的事件
      WeixinJSBridge.on('menu:setfont', function () {
        WeixinJSBridge.invoke('setFontSizeCallback', { 'fontSize': 0 });
      });
    }
  })();
}





//以帧为单位的setTimeout
export const setTimeout = (callback, frame) => {
  if (frame > 0 && callback) return setTimer(callback, frame, false);
}

export const clearTimeout = (timer) => {
  if (timer && timer.timer) clearTimer(timer);
} //edn func

//以帧为单位的setInterval
export const setInterval = (callback, frame) => {
  if (frame > 0 && callback) return setTimer(callback, frame, true);
} //edn func

export const clearInterval = (timer) => {
  if (timer && timer.timer) clearTimer(timer);
} //edn func

export const clearTimer = (timer) => {
  cancelAnimationFrame(timer.timer);
  timer = null;
}

function setTimer(callback, frame, interval) {
  var timer = {
    now: 0,
    timer: null
  };
  timer_handler();
  return timer;

  function timer_handler() {
    // console.log(timer.timer);
    timer.now++;
    var timeup = timer.now == frame;
    if (timeup) {
      timer.now = 0;
      callback();
    } //end if
    if (interval || (!interval && !timeup)) timer.timer = requestAnimationFrame(timer_handler);
  } //end func

} //edn func
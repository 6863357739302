export const data = {
    "sourceW": 200,
    "sourceH": 270,
    "w": 1955,
    "h": 1612,
    "file": "sprites.png",
    "frames": [
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 885,
            "y": 807
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1061,
            "y": 807
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1251,
            "y": 0
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1251,
            "y": 268
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1251,
            "y": 536
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1251,
            "y": 804
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 0,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 176,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 352,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 528,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 704,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 880,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1056,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1232,
            "y": 1076
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1427,
            "y": 0
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1427,
            "y": 268
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1427,
            "y": 536
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1427,
            "y": 804
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1427,
            "y": 1072
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1603,
            "y": 0
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1603,
            "y": 268
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1603,
            "y": 536
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1603,
            "y": 804
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1603,
            "y": 1072
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 0,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 176,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 352,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 528,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 704,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 880,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1056,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1232,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1408,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1584,
            "y": 1344
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1779,
            "y": 0
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1779,
            "y": 268
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1779,
            "y": 536
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 895,
            "y": 0
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 895,
            "y": 268
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 895,
            "y": 536
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 1073,
            "y": 0
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 1073,
            "y": 268
        },
        {
            "width": 178,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 1073,
            "y": 536
        },
        {
            "width": 179,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 537,
            "y": 538
        },
        {
            "width": 179,
            "height": 268,
            "offX": 13,
            "offY": 1,
            "x": 716,
            "y": 538
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 0,
            "y": 0
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 179,
            "y": 0
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 358,
            "y": 0
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 537,
            "y": 0
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 0,
            "y": 269
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 179,
            "y": 269
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 358,
            "y": 269
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 537,
            "y": 269
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 716,
            "y": 0
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 716,
            "y": 269
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 0,
            "y": 538
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 179,
            "y": 538
        },
        {
            "width": 179,
            "height": 269,
            "offX": 13,
            "offY": 0,
            "x": 358,
            "y": 538
        },
        {
            "width": 177,
            "height": 269,
            "offX": 15,
            "offY": 0,
            "x": 0,
            "y": 807
        },
        {
            "width": 177,
            "height": 269,
            "offX": 15,
            "offY": 0,
            "x": 177,
            "y": 807
        },
        {
            "width": 177,
            "height": 269,
            "offX": 15,
            "offY": 0,
            "x": 354,
            "y": 807
        },
        {
            "width": 177,
            "height": 269,
            "offX": 15,
            "offY": 0,
            "x": 531,
            "y": 807
        },
        {
            "width": 177,
            "height": 269,
            "offX": 15,
            "offY": 0,
            "x": 708,
            "y": 807
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1779,
            "y": 804
        },
        {
            "width": 176,
            "height": 268,
            "offX": 15,
            "offY": 1,
            "x": 1779,
            "y": 1072
        }
    ],
    "animations": {
        "loading-序列帧": [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64]
    }
}
<template>
    <canvas id="canvas"></canvas>
</template>
<script>
export default {
  props: ["loadData", "options",'src'],
  components: {},
  data() {
    return {};
  },

  mounted: function () {
 
    // return;
    // console.log(document.getElementById('canvas'));
    var canvas = document.getElementById('canvas'),
            ctx = canvas.getContext('2d'),
            frames = this.loadData.frames,
            i = 0,
            o = {},
            key = Object.keys(this.loadData.animations)[0],
            list = this.loadData.animations[key],
            len = list.length;

            let file = this.src,isFrame=0;
    console.log('loadData',this.src);

             canvas.width = this.loadData.sourceW;
        canvas.height = this.loadData.sourceH;

        let cacheCanvas = document.createElement("canvas"),
            ctxCache = cacheCanvas.getContext("2d");

        cacheCanvas.width = canvas.width;
        cacheCanvas.height = canvas.height;



        // console.log( this.loadData);

        window.timer = this.util.setInterval(()=>{
            o = list[i];
            ctxCache.clearRect(0, 0, canvas.width, canvas.height);
            o = Object.prototype.toString.call(o)=='[object Array]'? o: [o];
            for (var j = 0, t; j < o.length; j++) {
                t = frames[o[j]];
                ctxCache.drawImage(file, (t.x || this.loadData.x || 0), (t.y || this.loadData.y || 0), (t.width || this.loadData.width), (t.height || this.loadData.height), (t.offX || this.loadData.offX || 0), (t.offY || this.loadData.offY || 0), (t.width || this.loadData.width), (t.height || this.loadData.height));
            }
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(cacheCanvas, 0, 0, canvas.width, canvas.height);
            i = ++i === len? 0: i;

            if(i === len - 1 && !isFrame){
              this.$emit('callback');
            } 
        },4)
  // that.util.clearInterval(timer);

      

  },
  methods: {},
};
</script>

<template>
  <div class="page2 page">
    <div class="page bg_kg">
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
      <img src="~/img/lx1.png" alt="" />
    </div>
    <div class="qiye">
      <img src="~/img/qie.png" alt="" class="qiyeimg" />
      <img src="~/img/guan.png" alt="" class="guan" />
    </div>
    <div class="box weixin" v-if="isWeiXin">
      <div class="desc">
        <img src="~/img/p2-desc.png" alt="" />
        <img src="~/img/p2-desc-1.png" alt="" class="import shake-top" />
        <!-- <img src="~img/l1.png" alt="" class="" />
        <img src="~img/l2.png" alt="" class="" />
        <img src="~img/l3.png" alt="" class="" />
        <img src="~img/l4.png" alt="" class="" />
        <img src="~img/l5.png" alt="" class="" />
        <img src="~img/l6.png" alt="" class="" /> -->
      </div>
    </div>

    <div class="box noWeixin" v-else>
      <div class="desc">
        <img src="~/img/p2-desc.png" alt="" />
        <img src="~/img/p2-desc-1.png" alt="" class="import shake-top" />

        <!-- <img src="~img/l1.png" alt="" class="" />
        <img src="~img/l2.png" alt="" class="" />
        <img src="~img/l3.png" alt="" class="" />
        <img src="~img/l4.png" alt="" class="" />
        <img src="~img/l5.png" alt="" class="" />
        <img src="~img/l6.png" alt="" class="" /> -->
      </div>
      <div class="input">
        <img src="~img/p4.png" alt="" />
        <input
          type="text"
          placeholder="名字输入框"
          maxlength="7"
          v-model="name"
          ref="input"
        />
      </div>
      <div class="privacy">
        <div class="ania">
          <img src="~img/p7.png" alt="" class="txt" />
          <img src="~img/p8.png" alt="" class="st-btn" />
          <img src="~img/p9.png" alt="" class="st-btn" v-if="isSelect" />
          <div class="clickBtn" @click="isSelect = !isSelect"></div>
          <div class="gohref" @click="gohref"></div>
        </div>
      </div>
    </div>

    <div
      class="btn animated fadeInUp"
      :class="isWeiXin ? 'wx' : ''"
      @click="subNextPage"
    >
      <img src="~img/p5.png" alt="" />
      <div class="animation a5"></div>
    </div>

    <!-- <img src="~img/btns-1.svg" alt="" class="btn animated fadeInUp"> -->

    <layer :tips="tips" ref="layer"></layer>
  </div>
</template>
<script>
import txjsdk from "../assets/js/txjssdk";
export default {
  components: {},
  data() {
    return {
      name: "",
      isSelect: false,
      tips: 0,
    };
  },
  computed: {
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted: function () {
    this.$("input").on("blur", function () {
      setTimeout(() => {
        var scrollHeight =
          document.documentElement.scrollTop || document.body.scrollTop || 0;
        console.log("scrollHeight", scrollHeight);
        window.scrollTo(0, 0);
      }, 100);
    });
    // let clientHeight = document.body.clientHeight;
    // let offsetHeight = document.body.offsetHeight;
    // let scrollHeight = document.body.scrollHeight;
    // let height = window.screen.height;
    // let availHeight = window.screen.availHeight;
    // let innerHeight = window.innerHeight;
    // console.log("clientHeight", clientHeight);
    // console.log("offsetHeight", offsetHeight);
    // console.log("scrollHeight", scrollHeight);
    // console.log("height", height);
    // console.log("availHeight", availHeight);
    // console.log("innerHeight", innerHeight);

    // console.log(this.util);
    // 解决手机 键盘
    // console.log('input',this.$refs.input);
  },
  methods: {
    gohref() {
      location.href = "https://privacy.qq.com/policy/tencent-privacypolicy";
    },
    subNextPage() {
      this.gsap.to(".page2 .btn img", { duration: 0.4, scale: 1.05 });
      this.gsap.to(".page2 .btn img", { duration: 0.4, delay: 0.4, scale: 1 });

      if (!this.isWeiXin) {
        if (!this.name) {
          this.tips = 1;
          this.$refs.layer.tapShow();

          // this.$layer.alert("请输入姓名！");
          return;
        }
        let censoredWord = new this.censoredWord();
        if (!censoredWord.check(this.name).result) {
          this.tips = 2;
          this.$refs.layer.tapShow();
          // this.$layer.alert("包含敏感词汇");
          return;
        }
        if (!this.isSelect) {
          this.tips = 3;
          clearTimeout(this.tims);
          this.$(".page2 .ania").addClass("animated shake");
          this.tims = setTimeout(() => {
            this.$(".page2 .ania").removeClass("animated shake");
          }, 1000);
          // this.$refs.layer.tapShow();
          // this.$layer.alert("请同意用户隐私协议");
          return;
        }
        this.$root.name = this.name;
        this.report("click_start");
        this.$emit("nextPageFun");
      } else {
        txjsdk.handleGetUserInfo((userinfo) => {
          if (userinfo) {
            this.$root.openid = userinfo.openid;
            this.$root.name = userinfo.nickname;
          }
          this.report("click_start");
          this.$emit("nextPageFun");
          // location.href="t.html"

        });
      }
    },
  },
};
</script>

<style lang="less">
@vw: 7.5vw;
@vh: 13.33vh;

.qiye {
  width: 100%;
  top: 0;
  position: absolute;
  .qiyeimg {
    width: (248 / @vw);
    position: absolute;
    top: 0;
    right: 0;
    -webkit-animation: 1s both qiye 0.4s;
  }
  .guan {
    position: absolute;
    width: (1431 / @vw);
    top: (108 / @vw);
    left: (-680 / @vw);
    -webkit-animation: 1s both guan 1s;
    -webkit-transform-origin: (1210 / @vw) (70 / @vw);
  }
}

@-webkit-keyframes guan {
  0% {
    -webkit-transform: scale(0.1);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-webkit-keyframes qiye {
  0% {
    -webkit-transform: translateX((100 / @vw));
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
// qiyeimg
// guan

.page2 {
  .a5 {
    -webkit-transform: scale(0.9);
    left: (-40 / @vw);
    top: (-20 / @vw);
  }
}

.cls-1,
.cls-5,
.cls-8 {
  fill: none;
}
.cls-2 {
  fill: #02121d;
}
.cls-3 {
  clip-path: url(#clip-path);
}
.cls-4 {
  opacity: 0.44;
}
.cls-5,
.cls-8 {
  stroke: #fff;
  stroke-miterlimit: 10;
}
.cls-5 {
  stroke-width: 0.15px;
}
.cls-6,
.cls-7 {
  fill: #fff;
}
.cls-7 {
  font-size: 46px;
  font-family: SourceHanSansCN-Bold-GBpc-EUC-H, Source Han Sans CN;
  letter-spacing: 0.06em;
}
.cls-8 {
  stroke-width: 2px;
}

// @-webkit-keyframes trranx{
//   0%{transform: translateY((20/@vw)); opacity: 0;}
//   100%{transform: translateY(0); opacity: 1;}
// }
.page2 {
  .btn {
    position: absolute;
    left: (268 / @vw);
    width: (230 / @vw);
    height: (116 / @vw);
    // bottom: (116 / @vw);
    top: (1000 / @vw);
    -webkit-animation-delay: 1.8s;
    pointer-events: auto;

    &.wx {
      // bottom: (146 / @vw);
      top: (900 / @vw);
    }
    img {
      width: 100%;
      position: absolute;
      top: 0;
    }
  }
  // .btn {
  //   position: absolute;
  //   left: 50%;
  //   width: (332 / @vw);
  //   bottom: (156 / @vh);
  //   -webkit-animation-delay: 2.4s;
  //   margin-left: -(332/2 / @vw);
  //   pointer-events: auto;
  // }
  .box {
    width: 100%;
    position: absolute;
    left: 0;
    -webkit-animation-delay: 0.4s;

    &.noWeixin {
      height: (402 / @vw);
      top: (299 / @vw);
      .input {
        position: absolute;
        left: 50%;
        top: (500 / @vw);
        // bottom: (50 / @vw);
        width: (228 / @vw);
        height: (87 / @vw);
        margin-left: (-228/2 / @vw);
        -webkit-animation: 1s both fadeInUp 1.2s;
        img {
          width: 100%;
        }
        input {
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          text-align: center;
          font-size: (28 / @vw);
          color: #808080;
          box-sizing: border-box;
          padding: 0 (20 / @vw);
        }
      }
    }
    &.weixin {
      height: (402 / @vw);
      top: (320 / @vh);
    }
  }
  .desc {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-animation: 1s both fadeInUp 1.2s;

    img {
      width: 100%;
      position: absolute;
      top: 0;

      &.import {
        position: absolute;
        bottom: 0;
        top: auto;
      }
      // &:nth-child(2) {
      //   // -webkit-animation-delay: 0.4s;
      // }
      // &:nth-child(3) {
      //   -webkit-animation-delay: 0.4s;
      // }
      // &:nth-child(4) {
      //   -webkit-animation-delay: 1.2s;
      // }
      // &:nth-child(5) {
      //   -webkit-animation-delay: 1.2s;
      // }
      // &:nth-child(6) {
      //   -webkit-animation-delay: 1.2s;
      // }
    }
  }

  .privacy {
    position: absolute;
    left: 50%;
    // bottom: 0;
    top: (831 / @vw);
    width: (254 / @vw);
    // height: (25 / @vw);
    margin-left: (-254/2 / @vw);
    -webkit-animation: 1s both fadeInUp 2s;

    .txt {
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .st-btn {
      width: (22 / @vw);
      position: absolute;
      top: 0;
      left: 0;
    }
    .clickBtn {
      width: (290 / @vw);
      height: (50 / @vw);
      position: absolute;
      top: (-20 / @vw);
      left: (-20 / @vw);
      // margin-top: (-50/2 / @vw);
    }
    .gohref {
      width: (150 / @vw);
      height: (50 / @vw);
      position: absolute;
      top: (-20 / @vw);
      right: 0;
      // margin-top: (-50/2 / @vw);
    }
  }
}

.t-logo {
  position: absolute;
  left: (38 / @vw);
  top: (18 / @vw);
  width: (680 / @vw);
}
.b-logo {
  position: absolute;
  left: (368 / @vw);
  width: (346 / @vw);
  bottom: (17 / @vw);
}
.l-bar {
  position: absolute;
  left: 0 / @vw;
  top: (724 / @vw);
  width: (22 / @vw);
}
</style>

import $ from 'jquery'

var _shareInfo = {
    share_url: '',
    title: '',
    desc: '',
    image_url: '',
};
var ua = navigator.userAgent.toLowerCase();
console.log('ua', ua);

function isMqq() {
    return /mobile.*qq\/[\d.]+/.test(ua);
}

function isWechat() {
    return /micromessenger/.test(ua);
}

function isQB() {
    if (/mqqbrowser|QBWebViewUA|QBWebViewType/.test(ua)) {
        if (!isMqq() && !isWechat()) {
            return true;
        }
    }
    return false;
}

function isKB() {
    return /qnreading\/\d/ig.test(ua);
}

function isIOS() {
    return /iphone|ipad|ipod|itouch/.test(ua);
}

function isAndroid() {
    return /android/.test(ua);
}

function isWeibo() {
    return /sensation|weibo/.test(ua);
}
function setStorageWithExpires(key, data, expires) {
    expires = Date.now() + (expires * 1000 || 48 * 60 * 60 * 1000);
    localStorage.setItem(key, data);
    localStorage.setItem(key + '__expires__', expires);
}
function getStorageWithExpires(key) {
    var expires = localStorage.getItem(key + '__expires__');
    if (expires > Date.now()) {
        return localStorage.getItem(key);
    }
    return false;
}
function getAppType() {
    if (isMqq()) {
        return 1;
    }
    if (isKB()) {
        return 2;
    }
    if (isQB()) {
        return 3;
    }
    if (isWechat()) {
        return 4;
    }
    if (isWeibo()) {
        return 5;
    }
    return 0;
}
function editUrlParam(url, paramName, replaceWith) {
    if (url.indexOf(paramName) > -1) {
        var re = eval('/(' + paramName + '=)([^&]*)/gi');
        url = url.replace(re, paramName + '=' + replaceWith);
    } else {
        var paraStr = paramName + '=' + replaceWith;
        var idx = url.indexOf('?');
        if (idx < 0)
            url += '?';
        else if (idx >= 0 && idx != url.length - 1)
            url += '&';
        url = url + paraStr;
    }
    return url;
}
function handleGetShareUrl() {
    var url = 'https://' + window.location.host + '/';
    url = editUrlParam(url, 'share', getAppType());
    return url;
}
function getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg);  //匹配目标参数
    if (r != null) return unescape(r[2]); return null; //返回参数值
}

function handleGetUserInfo(callback) {
    if (isWechat()) {

        let url = 'http://demo1.test.yscase.com/';
        if (location.search) {
            url = url + location.search
        }
        if(url.indexOf('?') != -1){
            url += "&isJump=1" 
        }else{
            url += "?isJump=1" 
        }
        location.replace(url);
        return;
        // let data = getStorageWithExpires('userInfo');
        // if (data) {
        //     console.log('userinfo', data);
        //     callback && callback(data);
        //     return;
        // }
        $.ajax({
            type: 'GET',
            url: 'japi/userinfo',
            data: {},
            dataType: "json",
            timeout: 5000,
            success: function (res) {
                console.log('res', res);
                if (res.code == 1008) {
                    let url = 'https://tencentysf2021.qq.com/japi/index';
                    if (location.search) {
                        url = url + location.search
                    }
                    if(url.indexOf('?') != -1){
                        url += "&isJump=1" 
                    }else{
                        url += "?isJump=1" 
                    }
                    location.replace(url);
                    return;
                }
                console.log('userinfo', res.userinfo);
                var userInfo = res.userinfo;
                // setStorageWithExpires('userInfo', JSON.stringify(userInfo));
                callback && callback(userInfo);
                // }
            },
            error: function (xhr, type) {
                console.log(xhr, type);
                callback && callback();
            }
        });
    }
}

function handleSetShare(title, desc) {
    _shareInfo.share_url = handleGetShareUrl();
    console.log('share_url', _shareInfo.share_url);
    if (title) {
        _shareInfo.title = title;
    }
    if (desc) {
        _shareInfo.desc = desc;
    }
    if (isWechat()) {
        if (typeof WeixinJSBridge == "object" && typeof WeixinJSBridge.invoke == "function") {
            shareToWx();
        } else {
            if (document.addEventListener) {
                document.addEventListener("WeixinJSBridgeReady", shareToWx, false);
            } else if (document.attachEvent) {
                document.attachEvent("WeixinJSBridgeReady", shareToWx);
                document.attachEvent("onWeixinJSBridgeReady", shareToWx);
            }
        }
    }
}
function shareToWx() {
    var shareObj = {
        "img_url": _shareInfo.image_url,
        "link": _shareInfo.share_url,
        "desc": _shareInfo.desc,
        "title": _shareInfo.title
    }
    var shareCb = function (res) {
        // $(document).trigger('REPORT_BTN_SHARE_CLICK');
    }
    // 发送给好友
    WeixinJSBridge.on('menu:share:appmessage', function (argv) {
        WeixinJSBridge.invoke('sendAppMessage', shareObj, shareCb);
    });
    // 分享到朋友圈
    WeixinJSBridge.on('menu:share:timeline', function (argv) {
        WeixinJSBridge.invoke('shareTimeline', shareObj, shareCb);
    });
    // 分享到qq
    WeixinJSBridge.on('menu:share:qq', function (argv) {
        WeixinJSBridge.invoke('shareQQ', shareObj, shareCb);
    });

    ; (function () {

        try {
            isIOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
            if (isIOS) {
                // window.history.pushState({}, "title", "#");
                document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
                    WeixinJSBridge.call('hideToolbar');        // 隐藏底部状态栏
                    console.log('ios');
                    // WeixinJSBridge.call('showToolbar');        // 显示底部状态栏
                    // WeixinJSBridge.call('showOptionMenu');     // 显示右上角的三个点的选项
                });
    
            }
        } catch (e) {
            //TODO handle the exception
        }
    })();
}


function init(options) {
    _shareInfo.title = options.shareTitle || '';
    _shareInfo.desc = options.shareDesc || '';
    _shareInfo.image_url = options.shareImage || '';
    let getUserinfo = options.getUserinfo || '';

    //获取名称，openid
    getUserinfo && handleGetUserInfo(getUserinfo);

    //设置分享
    handleSetShare();

    // options.getUserinfo = 
}

export default {
    init,
    handleGetUserInfo
}
<template>
  <transition name="fade">
    <div class="page layer" v-show="isShow">
      <div class="layer-bg animated bounceInUp">
        <img src="~img/tips1.png" alt="" v-if="tips == 1" />
        <img src="~img/tips2.png" alt="" v-if="tips == 2" />
        <img src="~img/tips3.png" alt="" v-if="tips == 3" />
        <div class="layer-btn" @click="tapHide">
          <img src="~img/sbnt1.png" alt="">
           <div class="animation a5"></div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "layer",
  props: ["tips"],
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    tapShow() {
      this.isShow = true;
      // this.$emit("tapHide");
    },
    tapHide() {
      
      this.isShow = false;
    },
  },
};
</script>

<style lang="less">
@vw: 7.5vw;
.layer {
  background: rgba(0, 0, 0, 0.6);
  .a5{
        -webkit-transform: scale(0.7);
    left: (-60 / @vw);
    top: (-35 / @vw);
  }
  .layer-bg {
    width: (410 / @vw);
    height: (283 / @vw);
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -(205 / @vw);
    margin-top: -(410/2 / @vw);
    background: url("~img/layer-bg.png") no-repeat;
    background-size: 100% auto;

  }

  img {
    width: 100%;
    position: absolute;
    top: 0;
  }
}
.layer-btn {
  position: absolute;
  left:( 114/@vw);
  top: (159/@vw);
  width: (182/@vw);
  height: (92/@vw);
  img{
    width:100%;
    position: absolute;
    top: 0;left: 0;
  }
}
</style>
